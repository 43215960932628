<template>
  <div>
    <OneTable
      :mainHeader="'עבר לביצוע'"
      :postState="'inProgress'"
      :key="'InProgress'"
      :trucking="trucking"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import OneTable from "@/components/app/common/Table/OneTable2";

export default {
  props: {
    trucking: {
      default: false,
    },
  },
  data: () => ({}),
  components: {
    OneTable,
  },
  computed: {
    ...mapGetters(["loading", "lagguageHeaders"]),
  },
};
</script>

<style>
</style>