<template>
  <div>
    <OneTable
      :mainHeader="'ארכיון'"
      :postState="'Archives'"
      :key="'Archives'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import OneTable from "@/components/app/common/Table/OneTable2";

export default {
  data: () => ({
    addDialog: false,
    values: {},
  }),
  components: {
    OneTable,
  },
  computed: {
    ...mapGetters(["loading", "lagguageHeaders"]),
  },
};
</script>

<style>
</style>